<template>
  <v-form v-model="valid">
    <v-container class="pa-0" fluid>
      <v-row dense>
        <v-col cols="4">
          <v-checkbox
            v-model="localFormData.CATS"
            :label="$t('FORMS.PET_PERMIT.CATS')"
            hide-details
            class="ma-0"
          ></v-checkbox>
        </v-col>
        <v-col cols="8">
          <v-switch
            v-if="localFormData.CATS"
            v-model="localFormData.HOUSE_CAT"
            :label="$t('FORMS.PET_PERMIT.HOUSE_CAT')"
            hide-details
            inset
            class="ma-0"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-select
            v-if="localFormData.CATS"
            v-model="localFormData.CATS_NUMBER"
            :rules="[rules.required]"
            :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
            :label="$t('FORMS.PET_PERMIT.CATS_NUMBER') + ' *'"
            hide-details
            solo
            class="mt-2 mb-4"
            :append-icon="errors.cats_number ? 'mdi-alert-circle-outline' : 'mdi-chevron-down'"
            @update:error="errors = { ...errors, cats_number: $event }"
          ></v-select>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="localFormData.DOGS"
            :label="$t('FORMS.PET_PERMIT.DOGS')"
            hide-details
            class="ma-0 pa-0"
          ></v-checkbox>
        </v-col>
        <v-col v-if="localFormData.DOGS" cols="12">
          <v-select
            v-model="localFormData.DOGS_NUMBER"
            :rules="[rules.required]"
            :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
            :label="$t('FORMS.PET_PERMIT.DOGS_NUMBER') + ' *'"
            hide-details
            solo
            :append-icon="errors.dogs_number ? 'mdi-alert-circle-outline' : 'mdi-chevron-down'"
            class="mt-2"
            @update:error="errors = { ...errors, dogs_number: $event }"
          ></v-select>
        </v-col>
        <v-col v-if="localFormData.DOGS" cols="12">
          <v-text-field
            v-model="localFormData.RACE"
            :rules="[rules.required]"
            class="ma-0 pa-0"
            counter="30"
            :label="$t('FORMS.PET_PERMIT.RACE') + ' *'"
            hide-details
            solo
            :append-icon="errors.race ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, race: $event }"
          ></v-text-field>
        </v-col>
        <v-col v-if="localFormData.DOGS" cols="12">
          <v-text-field
            v-model="localFormData.SHOULDER_HEIGHT"
            class="ma-0 pa-0"
            :label="$t('FORMS.PET_PERMIT.SHOULDER_HEIGHT')"
            hide-details
            solo
            counter="3"
            :append-icon="errors.shoulder_height ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, shoulder_height: $event }"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="localFormData.OTHER"
            :label="$t('FORMS.PET_PERMIT.OTHER')"
            hide-details
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col v-if="localFormData.OTHER">
          <v-text-field
            v-model="localFormData.PET"
            :rules="[rules.required]"
            class="ma-0 pa-0"
            counter="50"
            :label="$t('FORMS.PET_PERMIT.PET') + ' *'"
            hide-details
            solo
            :append-icon="errors.pet ? 'mdi-alert-circle-outline' : null"
            @update:error="errors = { ...errors, pet: $event }"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense text-center>
        <v-col cols="12">
          <slot name="button" :valid="valid" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <slot name="description" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/app/modules/_global/classes/Rules";

export default {
  name: "PetPermitForm",

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    localFormData: null,

    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  computed: {
    valid: {
      get() {
        return (
          (this.localFormData.CATS && typeof this.localFormData.CATS_NUMBER == "string") ||
          (this.localFormData.DOGS &&
            typeof this.localFormData.DOGS_NUMBER == "string" &&
            typeof this.localFormData.RACE == "string" &&
            this.localFormData.RACE != "") ||
          (this.localFormData.OTHER &&
            typeof this.localFormData.PET == "string" &&
            this.localFormData.PET != "")
        );
      },
      set() {
        //
      }
    }
  },

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    setFormData() {
      this.$emit("input", this.localFormData);
    }
  }
};
</script>
